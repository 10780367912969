import {
	QUERY_PICKSTATE_ALL_KEY,
	QUERY_PICKSTATE_CREATE_KEY,
	QUERY_PICKSTATE_DELETE_KEY,
	QUERY_PICKSTATE_KEY,
	QUERY_PICKSTATE_PUT_KEY
} from '@/const/pickstate.const'
import pickstateService from '@/services/pickstate.service'
import {
	IDtoPostPickstate,
	IDtoPutPickstate,
	IPickstateParams
} from '@/types/pickstate.types'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'

export const useGetPickstateAllQuery = (params?: IPickstateParams) =>
	useQuery({
		queryKey: [QUERY_PICKSTATE_ALL_KEY],
		queryFn: async () =>
			await pickstateService.getAll(params).then(res => res.data)
	})

export const useGetPickstateQuery = (id: number) =>
	useQuery({
		queryKey: [QUERY_PICKSTATE_KEY, id],
		queryFn: async () =>
			await pickstateService.getById({ id }).then(res => res.data)
	})

export const usePostPickstateQuery = () => {
	const queryClient = useQueryClient()
	return useMutation({
		mutationKey: [QUERY_PICKSTATE_CREATE_KEY],
		onSuccess: (data, variables) => {
			queryClient.invalidateQueries({ queryKey: [QUERY_PICKSTATE_ALL_KEY] })
		},
		mutationFn: async (data: IDtoPostPickstate) =>
			await pickstateService.create(data).then(res => res.data)
	})
}
export const usePutPickstateQuery = (params?: IPickstateParams) => {
	const queryClient = useQueryClient()
	return useMutation({
		mutationKey: [QUERY_PICKSTATE_PUT_KEY],
		onSuccess: (data, variables) => {
			queryClient.invalidateQueries({ queryKey: [QUERY_PICKSTATE_ALL_KEY] })
		},
		mutationFn: async (data: IDtoPutPickstate) =>
			await pickstateService.update(data).then(res => res.data)
	})
}

export const useDeletePickstateQuery = () => {
	const queryClient = useQueryClient()
	return useMutation({
		mutationKey: [QUERY_PICKSTATE_DELETE_KEY],
		onSuccess: (data, variables) => {
			queryClient.invalidateQueries({ queryKey: [QUERY_PICKSTATE_ALL_KEY] })
		},
		mutationFn: async (id: number) =>
			await pickstateService.delete({ id }).then(res => res.data)
	})
}
