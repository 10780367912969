import { $authHost } from '@/API'
import {
	IDtoPostRouteMapStatus,
	IDtoPutRouteMapStatus,
	IRouteMapStatus,
	IRouteMapStatusHistoryParams,
	IRouteMapStatusHistoryResponse,
	IRouteMapStatusParams,
	IRouteMapStatusWithPagination
} from '@/types/routeMapStatus2.types'

class RouteMapStatusService {
	getAll = (params?: IRouteMapStatusParams) => {
		return $authHost.get<IRouteMapStatusWithPagination>(
			'api/route_map_status/',
			{
				params: params
			}
		)
	}

	getById = ({ id }: { id: number }) => {
		return $authHost.get<IRouteMapStatus>('api/route_map_status/' + id)
	}

	getHistory = (params: IRouteMapStatusHistoryParams) => {
		return $authHost.get<IRouteMapStatusHistoryResponse>(
			`api/route_map_status_history/`,
			{
				params
			}
		)
	}

	create = (data: IDtoPostRouteMapStatus) => {
		return $authHost.post<IRouteMapStatus>('api/route_map_status/', data)
	}

	update = (data: IDtoPutRouteMapStatus) => {
		return $authHost.put<IRouteMapStatus>(
			'api/route_map_status/' + data.id,
			data
		)
	}

	delete = ({ id }: { id: number }) => {
		return $authHost.delete<IRouteMapStatus>('api/route_map_status/' + id)
	}
}

export default new RouteMapStatusService()
