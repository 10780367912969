import { useGetProductionOrderStateAllQuery } from '@/hooks/productionOrderState.hook'
import { useGetRouteMapStatusAllQuery } from '@/hooks/routeMapStatus.hook'
import {
	usePostRoutemapComlateByLKKQuery,
	usePutRoutemapQuery
} from '@/hooks/routemap.hook'
import { IRouteMap } from '@/types/Routemap.types'
import { DownloadOutlined } from '@ant-design/icons'
import {
	Button,
	DatePicker,
	Popconfirm,
	Switch,
	TableColumnsType,
	Tag,
	message
} from 'antd'
import dayjs from 'dayjs'
import { useEffect } from 'react'
import { NavLink } from 'react-router-dom'

export const useGetTableRoutemap = (
	setIsOpen: (arg: boolean) => void,
	setSelectedRouteMap: (arg: number[]) => void
): TableColumnsType<IRouteMap> => {
	const {
		mutate,
		isPending,
		isError: isPutError,
		isSuccess: isPutSuccess,
		error: PutError
	} = usePutRoutemapQuery({})

	const {
		mutate: complate,
		isPending: isLkkComplateLoading,
		isError: isLkkComplateError,
		isSuccess: isLkkComplateSuccess,
		error: LkkComplateError
	} = usePostRoutemapComlateByLKKQuery()

	useEffect(() => {
		message.destroy()
		if (isPutSuccess || isLkkComplateSuccess) {
			message.success('Данные успешно обновлены')
		}
		if (isLkkComplateError) {
			message.error('Ошибка! Данные не обновлены! ' + LkkComplateError.message)
		}
		if (isPutError) {
			message.error('Ошибка! Данные не обновлены! ')
		}
		if (isPending || isLkkComplateLoading) {
			message.loading('Обновление...')
		}
	}, [
		isPutError,
		isPutSuccess,
		isPending,
		isLkkComplateLoading,
		isLkkComplateError,
		isLkkComplateSuccess
	])

	const states = useGetProductionOrderStateAllQuery({ all: true })
	const routemapStates = useGetRouteMapStatusAllQuery()

	const start_state = routemapStates?.data?.data.find(e => e.start_state)
	const done_state = routemapStates?.data?.data.find(e => e.done_state)
	const cancelled_state = routemapStates?.data?.data.find(
		e => e.cancelled_state
	)
	const process_state = routemapStates?.data?.data.find(e => e.process_state)
	const created_state = routemapStates?.data?.data.find(e => e.created_state)

	return [
		{
			title: 'К операциям',
			dataIndex: '№',
			key: '№',
			render(v, routemap) {
				return (
					<NavLink to={`/manual-loader/${routemap.id}`}>
						<Button size='large' type='primary'>
							Перейти
						</Button>
					</NavLink>
				)
			}
		},
		{
			title: '№ МК',
			dataIndex: '№',
			key: '№',
			render(value, routemap, index) {
				let color = 'blue'
				let text = 'InCanLine'

				if (routemap.is_production_) {
					text = 'Производственная'
					color = 'cyan'
				}
				if (routemap.is_packing_) {
					text = 'Фасовочная'
					color = 'orange'
				}
				if (routemap.sb_test_part) {
					text = 'Тестовая'
					color = 'green'
				}
				if (routemap.add_packing) {
					text = 'Дофасовка'
					color = 'red'
				}

				return (
					<NavLink to={`/manual-loader/${routemap.id}`}>
						<div className='flex flex-col gap-3 text-center '>
							<p className='text-xl'>{routemap.number}</p>
							<Tag className='w-full flex justify-center' color={color}>
								<p>{text}</p>
							</Tag>
							{!routemap.sb_test_part && (
								<Tag className='w-full flex justify-center' color='yellow'>
									<p>Основная</p>
								</Tag>
							)}
						</div>
					</NavLink>
				)
			}
		},
		{
			title: '№ ОМС',
			dataIndex: 'Отчёт мастера смены',
			key: 'Отчёт мастера смены',
			render(value, routemap, index) {
				return routemap.report_number
			}
		},
		{
			title: 'Номер',
			dataIndex: 'Номер',
			key: 'Номер',
			children: [
				{
					title: 'Партии',
					dataIndex: 'Партии',
					key: 'Партии',
					align: 'center',
					render(value, routemap, index) {
						return <span>{routemap.sb_num_part}</span>
					}
				},
				{
					title: 'Программы',
					dataIndex: 'Программы',
					key: 'Программы',
					align: 'center',
					render(value, routemap, index) {
						return <span>{routemap.executing_programm_num}</span>
					}
				}
			]
		},
		{
			title: 'Производство',
			dataIndex: 'Производство',
			key: 'Производство',
			children: [
				{
					title: 'Начало',
					dataIndex: 'Начало',
					key: 'Начало',
					render(value, routemap, index) {
						return (
							<DatePicker
								className='w-[100px]'
								format={'DD.MM.YY'}
								showTime
								value={routemap.start ? dayjs(routemap.start) : undefined}
							/>
						)
					}
				},
				{
					title: 'Окончание',
					dataIndex: 'Окончание',
					key: 'Окончание',
					render(value, routemap, index) {
						return (
							<DatePicker
								className='w-[100px]'
								placeholder='Не указано'
								format={'DD.MM.YY'}
								showTime
								value={routemap.end ? dayjs(routemap.end) : undefined}
							/>
						)
					}
				}
			]
		},
		{
			title: 'Запланировано',
			dataIndex: 'Запланировано',
			align: 'center',
			key: 'Запланировано',
			render(value, routemap, index) {
				return <span>{routemap.planned}</span>
			}
		},
		{
			title: 'Статус задачи',
			dataIndex: 'Статус задачи',
			key: 'Статус задачи',
			render(value, routemap, index) {
				return (
					<>
						{routemapStates?.data?.data.map((e, idx) => {
							if (e.id !== routemap?.status_id?.id) return
							return (
								<Tag
									color={['green', 'red', 'blue', 'yellow', 'orange'][idx]}
									className='font-bold'
								>
									{e.description}
								</Tag>
							)
						})}
					</>
				)
			}
		},
		{
			title: 'Номенклатура',
			dataIndex: 'Номенклатура',
			key: 'Номенклатура',
			width: '370px',
			render(value, routemap, index) {
				return routemap.product_name
			}
		},
		{
			title: 'Ручное выполнение',
			dataIndex: 'Ручное выполнение',
			key: 'Ручное выполнение',
			align: 'center',
			render(value, routemap, index) {
				return (
					<Switch
						checked={routemap.kvil_manual_exec || false}
						onChange={value => {
							mutate({ id: routemap.id, kvil_manual_exec: value })
						}}
					/>
				)
			}
		},
		{
			title: 'Действие',
			dataIndex: 'Действие',
			key: 'Действие',
			render(value, routemap, index) {
				return (
					<div className='flex flex-col gap-2'>
						<Button
							type='primary'
							onClick={() => {
								setIsOpen(true)
								setSelectedRouteMap([routemap.id])
							}}
							icon={<DownloadOutlined />}
							className='font-bold'
						>
							Скачать
						</Button>
						{routemap?.status_id?.id === created_state?.id && (
							<Button
								onClick={() => {
									mutate({
										id: routemap.id,
										start_state: true
									})
								}}
								className='font-bold'
							>
								Начать выполнение
							</Button>
						)}
						{routemap?.status_id?.id === done_state?.id && (
							<Button type='primary' disabled className='font-bold'>
								Завершена
							</Button>
						)}
						{routemap?.status_id?.id === cancelled_state?.id && (
							<Button type='primary' disabled className='font-bold'>
								Отменена
							</Button>
						)}
						{(routemap?.status_id?.id === process_state?.id ||
							routemap?.status_id?.id === start_state?.id) && (
							<Popconfirm
								title={'Вы уверены?'}
								description='Все операции перейдут в статус выполнен'
								okText='Да, уверен'
								cancelText='Нет, отменить'
								onConfirm={() => {
									mutate({
										id: routemap.id,
										count_expend: true,
										finish_state: true
									})
								}}
							>
								<Button type='primary' className='font-bold'>
									Завершить операции
								</Button>
							</Popconfirm>
						)}
						{(routemap?.status_id?.id === process_state?.id ||
							routemap?.status_id?.id === start_state?.id) &&
							routemap?.is_production_ &&  (
								<Popconfirm
									title={'Вы уверены?'}
									description='Все операции перейдут в статус выполнен'
									okText='Да, уверен'
									cancelText='Нет, отменить'
									onConfirm={() => {
										complate({ id: routemap.id })
									}}
								>
									<Button type='primary' className='font-bold'>
										Завершить до ЛКК
									</Button>
								</Popconfirm>
							)}
					</div>
				)
			}
		}
	]
}
