import './App.css'
import AppRouter from './components/AppRouter'
import { useMount } from './hooks/useMount'
import Company from './store/Company/Company'
import SettingsCollectSystem from './store/Settings/SettingsCollectSystem/SettingsCollectSystem'
import Theme from './store/Theme'
import User from './store/user'
import { Language } from './utils/langSwitch'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ConfigProvider, ThemeConfig } from 'antd'
import RussianLanguage from 'antd/locale/ru_RU'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { BrowserRouter } from 'react-router-dom'

dayjs.extend(utc)

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			refetchOnWindowFocus: true,
			refetchInterval: query => {
				// Проверяем, была ли ошибка
				if (query.state.status === 'error') {
					return 30000 // Запрашиваем каждые 30 секунд, если была ошибка
				}
				return false // Отключаем интервал, если ошибки не было
			}
		}
	}
})

const theme: ThemeConfig = {
	components: {
		Message: {
			fontSize: 30,
		}
	}
}

const App = () => {
	useMount(() => {
		// Handlers
		Theme.handleThemeChange()
		Language.handleLanguage()
		Company.handleCompany()
		User.handleLogin()
		SettingsCollectSystem.HandleReduceCollect()
	})
	return (
		<QueryClientProvider client={queryClient}>
			<ConfigProvider
				slider={{
					className: 'bg-gray-200'
				}}
				theme={theme}
				locale={RussianLanguage}
			>
				<BrowserRouter>
					<AppRouter />
				</BrowserRouter>
			</ConfigProvider>
		</QueryClientProvider>
	)
}
export default App
