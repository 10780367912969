import { $authHost } from '@/API'
import {
	IDtoPostRouteOperation,
	IDtoPutRouteOperation,
	IRouteOperation,
	IRouteOperationHistoryParams,
	IRouteOperationHistoryResponse,
	IRouteOperationParams
} from '@/types/RouteOperation.types'

class RouteOperationService {
	getAll = (params?: IRouteOperationParams) => {
		return $authHost.get<IRouteOperation[]>('api/route_operation/', {
			params
		})
	}

	getById = ({ id }: { id?: number | null }) => {
		return $authHost.get<IRouteOperation>(`api/route_operation/${id}`)
	}

	getHistory = (params: IRouteOperationHistoryParams) => {
		return $authHost.get<IRouteOperationHistoryResponse>(
			`api/operation_history/`,
			{
				params
			}
		)
	}

	create = (product: IDtoPostRouteOperation) => {
		return $authHost.post<IRouteOperation>('api/route_operation/', product)
	}

	update = (data: IDtoPutRouteOperation | IDtoPutRouteOperation[]) => {
		if (Array.isArray(data)) {
			return $authHost.put<IRouteOperation>('api/route_operation/', data)
		}
		return $authHost.put<IRouteOperation>(
			'api/route_operation/' + data.id,
			data
		)
	}

	delete = ({ id }: { id: number }) => {
		return $authHost.delete<IRouteOperation>('api/route_operation/' + id)
	}
}

export default new RouteOperationService()
